import React from "react";

export const Upload = ({
  color = "blue",
  size = "100%",
  className = "",
  style = {}
}) => (
  <svg
    viewBox="0 0 39.272 36"
    className={`svg-icon ${className || ""}`}
    style={{ width: size, ...style }}
  >
    <path
      id="Union_9"
      data-name="Union 9"
      fill={color}
      d="M-5771.419-152a.582.582,0,0,1-.581-.581v-6.968a.582.582,0,0,1,.581-.581.581.581,0,0,1,.581.581v6.387h33.678v-6.387a.581.581,0,0,1,.58-.581.581.581,0,0,1,.581.581v6.968a.581.581,0,0,1-.581.581Zm16.838-5.807a.579.579,0,0,1-.58-.579v-16.84h-6.968a.581.581,0,0,1-.516-.311.591.591,0,0,1-.065-.269.575.575,0,0,1,.1-.332l8.129-11.613A.583.583,0,0,1-5754-188a.582.582,0,0,1,.477.248l8.129,11.613a.583.583,0,0,1,.038.6.578.578,0,0,1-.515.311h-6.967v16.84a.581.581,0,0,1-.581.579Z"
      transform="translate(5772 188)"
    />
  </svg>
);
