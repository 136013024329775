import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import shortId from 'shortid';

import './Radio.css';

const Radio = ({ id, label, style, name, className, ...rest }) => {
  const properties = {
    ...rest,
    className: 'Radio ' + className,
    type: 'radio',
  };

  return (
    <Fragment>
      <div style={style}>
        <input type="radio" id={id} name={name || id} {...properties} />
        {label && (
          <label htmlFor={id} style={{ fontWeight: 'normal', fontSize: 14 }}>
            {label}
          </label>
        )}
      </div>
    </Fragment>
  );
};

//proptypes definition
Radio.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

//default proptypes
Radio.defaultProps = {
  id: shortId.generate(),
  label: '',
  name: '',
  onChange: null,
  className: '',
};

export default Radio;
