import Axios from "axios";
import { USERTOKEN } from "./data";

export const axiosFunc = (
  method,
  url,
  data,
  headers,
  callback,
  type = null
) => {
  let header = headers;
  if (headers === "yes") {
    header = { Authorization: `Bearer ${localStorage.getItem(USERTOKEN)}` };
  }
  Axios({
    method,
    url,
    data,
    headers: header,
  }).then(
    (res) => {
      callback(true, res, type);
    },
    (err) => {
      callback(false, err, type);
    }
  );
};

export const axiosMed = (method, url, data, headers) => {
  let header = headers;
  if (headers === "yes") {
    header = { Authorization: `Bearer ${localStorage.getItem(USERTOKEN)}` };
  }

  return Axios({
    method,
    url,
    data,
    headers: header,
  });
};

export const formatCurrency = (data) => {
  if (data) {
    return data.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  }
};

export const sorter = (a, b) => (a > b ? 1 : a < b ? -1 : 0);

export const transformToCSV = (headers = [], data = []) => {
  const body = [];

  data.forEach((datum) => {
    body.push(headers.map(([key]) => datum[key]).join(","));
  });

  body.unshift(headers.map(([, displayName]) => displayName).join(","));

  const a = document.createElement("a");
  a.download = `Experiments Report (${new Date().toDateString()}).csv`;
  a.href =
    "data:text/csv;charset=utf-8," + encodeURIComponent(body.join("\r\n"));
  a.click();
  a.remove();
};

export const currentDate = () => new Date().toLocaleString("en-NG");
