export { Award } from "./Award";
export { CheckCirle } from "./CheckCircle";
export { Dashboard } from "./Dashboard";
export { Wallet } from "./Wallet";
export { Gear } from "./Gear";
export { Group } from "./Group";
export { Upload } from "./Upload";
export { Add } from "./Add";
export { DownLoad } from "./Download";
export { Eye } from "./Eye";
export { File } from "./File";
export { Level } from "./Level";
export { Logout } from "./Logout";
