import React from "react";
import {withRouter} from "react-router-dom";
import {Alert} from "antd";
import {USERTOKEN} from "../utils/data";

function Message(props) {

    let notifications = props.notifications;
    if (!Array.isArray(notifications)) {
        notifications = [notifications]
    }
    let stackAlert = (notification, index) => {
        if (notification && notification.description) {
            let description = notification.description;
            if (!notification.link) {
                return <div key={index}>
                    <Alert
                        showIcon={true}
                        type={notification.level || 'info'}
                        closable
                        description={description} message={""}/>
                </div>
            } else {
                return <div key={index}>
                    <Alert
                        key={index}
                        showIcon={true}
                        type={notification.level || 'info'}
                        onClose={() => window.location.href = `${notification.link}?token=${localStorage.getItem(USERTOKEN)}`}
                        description={description}
                        closable
                        closeText={notification.linkText || "Click here to continue"}
                        message={""}/><br/>
                </div>
            }
        }

    };
    return (
        <div>
            {
                notifications.map((notification, index) => {
                    return stackAlert(notification, index);
                })
            }
        </div>

    );

}

export default withRouter(Message);
