import React from "react";
const AuthController = component => {
    const Authenticate = props => {
        const RenderComponent = props.component;
        return <RenderComponent {...props} />;

    };

    Authenticate.defaultProps = {
        component
    };
    return Authenticate;
};

export default AuthController;
