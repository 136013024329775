import React from "react";

export const Gear = ({
  color = "blue",
  size = "100%",
  className = "",
  style = {}
}) => (
  <svg
    viewBox="0 0 39.272 36"
    className={`svg-icon ${className || ""}`}
    style={{ width: size, ...style }}
  >
    <path
      id="Path_83"
      data-name="Path 83"
      fill={color}
      d="M36.282,15.873,31.829,15.5a13.181,13.181,0,0,0-1.286-3.1L33.432,8.99a.782.782,0,0,0-.044-1.059l-3.321-3.32a.784.784,0,0,0-1.059-.045L25.594,7.457a13.209,13.209,0,0,0-3.1-1.286l-.371-4.453A.782.782,0,0,0,21.348,1h-4.7a.783.783,0,0,0-.78.718L15.5,6.171a13.209,13.209,0,0,0-3.1,1.286L8.99,4.568a.783.783,0,0,0-1.059.045L4.612,7.932A.782.782,0,0,0,4.568,8.99L7.457,12.4a13.176,13.176,0,0,0-1.286,3.1l-4.453.372A.782.782,0,0,0,1,16.652v4.7a.782.782,0,0,0,.718.779l4.453.372a13.181,13.181,0,0,0,1.286,3.1L4.568,29.008a.783.783,0,0,0,.044,1.06l3.321,3.321a.782.782,0,0,0,1.059.045l3.415-2.889a13.209,13.209,0,0,0,3.1,1.286l.371,4.453a.782.782,0,0,0,.779.717h4.7a.783.783,0,0,0,.78-.718l.371-4.453a13.209,13.209,0,0,0,3.1-1.286l3.415,2.889a.782.782,0,0,0,1.059-.045l3.321-3.321a.783.783,0,0,0,.044-1.06l-2.889-3.413a13.182,13.182,0,0,0,1.286-3.1l4.453-.372A.78.78,0,0,0,37,21.348v-4.7A.782.782,0,0,0,36.282,15.873ZM19,24.478A5.478,5.478,0,1,1,24.478,19,5.485,5.485,0,0,1,19,24.478Z"
      transform="translate(-1 -1)"
    />
  </svg>
);
