export const AdminAccessControl = {
  isAdmin: true,
  isProvider: false,
  sideBar: {
    dashboard: {
      dashboard: {
        total_subscribers: true,
        total_products: true,
        total_accounts: true,
        sub_accounts: false,
        total_revenue: true,
        revenue_graph: true,
        subscriptions_graph: true,
        churn_graph: true,
        mno_graph: true,
      },
      payment_transactions: true,
      subscriptions: true,
    },
    accounts: {
      filter: true,
      create_new: true,
      create_sub_account: true,
      show_list: true,
      single_account: {
        edit_account: true,
        create_sub_account: false,
        sub_accounts: false,
        basic_info: true,
        resource_usage: true,
        revenue: true,
        bank_details: true,
        decline: true,
        accept: true,
      },
    },
    subscribers: {
      search: true,
      data:{
      single_subscriber: {
        data: {
          user_info: true,
          subscription_logs: true,
          subscribed_service: true,
          subscriptions: true,
          wallet_amount: true,
          recent_transaction_logs: true,
          total_revenue: true,
        },
      }
    },
    },
    products: {
      filter: true,
      create_new: false,
      show_list: true,
      single_product: {
        dashboard_product_tab: {
          status: true,
          approve: true,
          sort: true,
          total_subscribers: true,
          total_contents: true,
          total_revenue: true,
          revenue_graphs: true,
          subscriptions_graphs: true,
          churn_graph: true,
        },
        content: {
          status: true,
          show_list: true,
          create_new: true,
        },
        transactions: true,
        subscriptions: true,
        edit_product: true,
        basic_info: true,
        disapprove: true,
        approve: true,
        settings: true,
      },
    },
    campaigns: {
      all_campaign: true,
      products: {
        push_campaign: true,
      },
      bulkSMS: {
        dashboard: true,
        messageChart: true,
        summary: true,
        report: true,
      },
    },
    finances: {
      total_revenue: true,
      wallet_balance: {
        top_wallet: false,
      },
      total_payable: {
        generate_invoice: false,
      },
      invoices: {
        approval: true,
      },
    },
    settings: {
      categories: true,
      networks: true,
      payment_channel: true,
      account_setup: true,
      revenue_sharing: true,
      finance: true,
      blacklist: true,
      bank_accounts: true,
      roles: true,
    },
    support: {
      tabSwitch: true,
      category: true,
      ticket: true,
      supportTicket: {
        viewTicket: true,
        createTicket: false,
        updateTicket: true,
      },
      supportCategories: {
        viewCategories: true,
        createCategories: true,
      },
    },
  },
  navBar: {
    earnings: {
      payable: true,
      total: true,
    },
  },
};
export const ProviderAccessControl = {
  isAdmin: false,
  isProvider: true,
  sideBar: {
    dashboard: {
      dashboard: {
        total_subscribers: true,
        total_products: true,
        sub_accounts: true,
        total_revenue: true,
        revenue_graph: true,
        subscriptions_graph: true,
        churn_graph: true,
        mno_graph: true,
      },
      payment_transactions: true,
      subscriptions: true,
    },
    accounts: {
      filter: false,
      create_new: false,
      create_sub_account: false,
      show_list: false,
      single_account: {
        edit_account: true,
        create_sub_account: true,
        sub_accounts: true,
        basic_info: true,
        resource_usage: false,
        revenue: false,
        bank_details: true,
        decline: false,
        accept: false,
      },
    },
    products: {
      filter: true,
      create_new: true,
      show_list: true,
      single_product: {
        dashboard_product_tab: {
          status: true,
          sort: true,
          total_subscribers: true,
          total_contents: true,
          total_revenue: true,
          revenue_graphs: true,
          subscriptions_graphs: true,
          churn_graph: true,
        },
        content: {
          status: true,
          show_list: true,
          create_new: true,
        },
        transactions: true,
        subscriptions: true,
        edit_product: true,
        basic_info: true,
        settings: true,
      },
    },
    campaigns: {
      all_campaign: true,
      products: {
        push_campaign: true,
      },
      bulkSMS: {
        dashboard: true,
        report: true,
      },
    },
    finances: {
      total_revenue: true,
      wallet_balance: {
        top_wallet: true,
      },
      total_payable: {
        generate_invoice: true,
      },
      invoices: {
        approval: false,
      },
    },
    profile: {
      basicInfo: true,
      bankDetail: true,
      subAccount: true,
    },
    support: {
      ticket: true,
      supportTicket: {
        viewTicket: true,
        createTicket: true,
      },
    },
    subscribers: {
      status: false,
      data: {
        search: true,
        single_subscriber: {
          status: true,
          data: {
            user_info: true,
            subscription_logs: true,
            subscribed_service: true,
            subscriptions: true,
            wallet_amount: true,
            recent_transaction_logs: true,
            total_revenue: true,
          },
        },
      },
    },
  },
  navBar: {
    earnings: {
      payable: true,
      total: true,
    },
  },
};

export const SubProviderAccessControl = {
  isAdmin: false,
  isProvider: true,
  sideBar: {
    dashboard: {
      dashboard: {
        total_subscribers: true,
        total_products: true,
        sub_accounts: true,
        total_revenue: true,
        revenue_graph: true,
        subscriptions_graph: true,
        churn_graph: true,
        mno_graph: true,
      },
      payment_transactions: true,
      subscriptions: true,
    },
    accounts: {
      filter: false,
      create_new: false,
      create_sub_account: false,
      show_list: false,
      single_account: {
        edit_account: false,
        create_sub_account: false,
        sub_accounts: false,
        basic_info: false,
        resource_usage: false,
        revenue: false,
        bank_details: false,
        decline: false,
        accept: false,
      },
    },
    products: {
      filter: true,
      create_new: true,
      show_list: true,
      single_product: {
        dashboard_product_tab: {
          status: true,
          sort: true,
          total_subscribers: true,
          total_contents: true,
          total_revenue: true,
          revenue_graphs: true,
          subscriptions_graphs: true,
          churn_graph: true,
        },
        content: {
          status: true,
          show_list: true,
          create_new: true,
        },
        transactions: true,
        subscriptions: true,
        edit_product: true,
        basic_info: true,
        settings: true,
      },
    },
    campaigns: {
      all_campaign: true,
      products: {
        push_campaign: true,
      },
      bulkSMS: {
        dashboard: true,
        report: true,
      },
    },
    finances: {
      total_revenue: true,
      wallet_balance: {
        top_wallet: true,
      },
      total_payable: {
        generate_invoice: true,
      },
      invoices: {
        approval: false,
      },
    },
    profile: {
      basicInfo: true,
      bankDetail: true,
      subAccount: true,
    },
    support: {
      ticket: true,
      supportTicket: {
        viewTicket: true,
        createTicket: true,
      },
    },
    subscribers: {
      status: false,
      data: {
        search: true,
        single_subscriber: {
          status: true,
          data: {
            user_info: true,
            subscription_logs: true,
            subscribed_service: true,
            subscriptions: true,
            wallet_amount: true,
            recent_transaction_logs: true,
            total_revenue: true,
          },
        },
      },
    },
  },
  navBar: {
    earnings: {
      payable: true,
      total: true,
    },
  },
};

export const ServiceProviderAccessControl = {
  isAdmin: false,
  isProvider: false,
  sideBar: {
    dashboard: {
      status: true,
      data: {
        dashboard: {
          status: true,
          data: {
            total_subscribers: true,
            total_products: true,
            total_accounts: false,
            sub_accounts: true,
            total_revenue: true,
            revenue_graph: true,
            subscriptions_graph: true,
            churn_graph: true,
            mno_graph: true,
          },
        },
        payment_transactions: true,
        subscriptions: true,
      },
    },
    accounts: {
      status: false,
      data: {
        filter: true,
        create_new: true,
        create_sub_account: false,
        show_list: true,
        single_account: {
          status: true,
          data: {
            edit_account: true,
            create_sub_account: true,
            sub_accounts: true,
            basic_info: true,
            resource_usage: false,
            revenue: false,
            bank_details: true,
            decline: false,
            accept: false,
          },
        },
      },
    },
    subscribers: {
      status: false,
      data: {
        search: true,
        single_subscriber: {
          status: true,
          data: {
            user_info: true,
            subscription_logs: true,
            subscribed_service: true,
            subscriptions: true,
            wallet_amount: true,
            recent_transaction_logs: true,
            total_revenue: true,
          },
        },
      },
    },
    products: {
      status: true,
      data: {
        filter: true,
        create_new: true,
        show_list: true,
        single_product: {
          status: true,
          data: {
            dashboard_product_tab: {
              status: true,
              data: {
                status: true,
                sort: true,
                approve: false,
                total_subscribers: true,
                total_contents: true,
                total_revenue: true,
                revenue_graphs: true,
                subscriptions_graphs: true,
                churn_graph: true,
              },
            },
            content: {
              status: true,
              data: {
                status: true,
                show_list: true,
                create_new: true,
              },
            },
            transactions: true,
            subscriptions: true,
            edit_product: true,
            basic_info: true,
            disapprove: false,
            approve: false,
            settings: true,
          },
        },
      },
    },
    campaigns: {
      status: true,
      data: {
        all_campaign: true,
        products: {
          status: true,
          data: {
            push_campaign: true,
          },
        },
        bulkSMS: {
          dashboard: true,
          report: true,
        },
      },
    },
    finances: {
      status: true,
      data: {
        total_revenue: true,
        wallet_balance: {
          status: true,
          data: {
            top_wallet: true,
          },
        },
        total_payable: {
          status: true,
          data: {
            generate_invoice: true,
          },
        },
        invoices: {
          status: true,
          data: {
            approval: false,
          },
        },
      },
    },
    settings: {
      status: false,
      data: {
        categories: true,
        networks: true,
        payment_channel: true,
        account_setup: true,
        revenue_sharing: true,
        finance: true,
        blacklist: true,
        bank_accounts: true,
        roles: true,
      },
    },
    profile: {
      status: true,
      data: {
        basicInfo: true,
        bankDetail: true,
        subAccount: true,
      },
    },
    support: {
      status: true,
      data: {
        tabSwitch: false,
        category: false,
        ticket: true,
        supportTicket: {
          status: true,
          data: {
            viewTicket: true,
            createTicket: true,
            updateTicket: false,
          },
        },
        supportCategories: {
          status: false,
          data: {
            viewCategories: false,
            createCategories: false,
          },
        },
      },
    },
  },
  navBar: {
    earnings: {
      status: true,
      data: {
        payable: true,
        total: true,
      },
    },
  },
};
