import React from "react";

export const File = ({
  color = "blue",
  size = "100%",
  className = "",
  style = {}
}) => (
  <svg
    viewBox="0 0 29.032 36"
    className={`svg-icon ${className || ""}`}
    style={{ width: size, ...style }}
  >
    <path
      id="Union_10"
      data-name="Union 10"
      fill={color}
      d="M-4284,36h-14.84A1.161,1.161,0,0,1-4300,34.838V1.161A1.161,1.161,0,0,1-4298.839,0h18V9.29a.581.581,0,0,0,.581.581h9.291V34.838A1.161,1.161,0,0,1-4272.129,36Zm-9.034-8.709a.58.58,0,0,0,.581.581h13.936a.58.58,0,0,0,.581-.581.581.581,0,0,0-.581-.582h-13.936A.581.581,0,0,0-4293.032,27.291Zm0-5.807a.58.58,0,0,0,.581.581h13.936a.58.58,0,0,0,.581-.581.58.58,0,0,0-.581-.581h-13.936A.58.58,0,0,0-4293.032,21.484Zm0-5.807a.581.581,0,0,0,.581.582h13.936a.581.581,0,0,0,.581-.582.58.58,0,0,0-.581-.581h-13.936A.58.58,0,0,0-4293.032,15.677Zm0-5.806a.58.58,0,0,0,.581.581h4.645a.581.581,0,0,0,.582-.581.581.581,0,0,0-.582-.581h-4.645A.58.58,0,0,0-4293.032,9.871Zm13.354-1.161V.921l7.79,7.789Z"
      transform="translate(4300)"
    />
  </svg>
);
